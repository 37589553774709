<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <!--begin::Card title-->
      <div class="card-title">
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="../media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            @input="searchItems()"
            class="form-control form-control-solid w-250px ps-15"
            placeholder="Search Associates"
          />
        </div>
        <!--end::Search-->
      </div>
      <!--begin::Card title-->
      <!--begin::Card toolbar-->
      <div class="card-toolbar">
        <!--begin::Toolbar-->
        <div
          class="d-flex justify-content-end"
          data-kt-customer-table-toolbar="base"
        >
          <!--begin::Add client-->
          <button
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#modal_new_licensed_distributor_associate"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="../media/icons/duotone/Navigation/Plus.svg" />
            </span>
            Add Associate
          </button>
          <!--end::Add client-->
        </div>
        <!--end::Toolbar-->
      </div>
      <!--end::Card toolbar-->
    </div>
    <div class="card-body pt-0" v-if="isDataReady">
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
      >
        <template v-slot:cell-name="{ row: licensedDist }">
          {{ licensedDist.name }}
        </template>
        <template v-slot:cell-email="{ row: licensedDist }">
          <a
            :href="'mailto:' + licensedDist.email"
            class="text-gray-600 text-hover-primary mb-1"
          >
            {{ licensedDist.email }}
          </a>
        </template>
        <template v-slot:cell-numOfClients="{ row: licensedDist }">
          {{ licensedDist.numOfClients }}
        </template>
        <template v-slot:cell-emailConfirmed="{ row: licensedDist }">
          <span
            v-show="licensedDist.emailConfirmed"
            class="badge badge-success mx-2"
            >Verified</span
          ><span
            v-show="!licensedDist.emailConfirmed"
            class="badge badge-danger mx-2"
            >Not Verified</span
          >
        </template>
        <template v-slot:cell-archive="{ row: licensedDist }">
          <span v-show="!licensedDist.archive" class="badge badge-success mx-2"
            >Active</span
          ><span v-show="licensedDist.archive" class="badge badge-danger mx-2"
            >Deactivated</span
          >
        </template>
        <template v-slot:cell-actions="{ row: licensedDist }">
          <router-link
            :to="{
              name: 'licensed-distributor-associate-clients',
              params: {
                accountid: String(licensedDist.associateAspNetUserId),
              },
            }"
            class="
              btn btn-icon btn-bg-light btn-active-color-primary btn-sm
              me-1
            "
          >
            <span class="svg-icon svg-icon-3">
              <inline-svg src="../media/icons/duotune/art/art005.svg" /> </span
          ></router-link>
          <a
            href="#"
            class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            @click="
              deleteLicensedDistributorAssociate(
                licensedDist.licensedDistributorId
              )
            "
          >
            <span class="svg-icon svg-icon-3">
              <inline-svg src="../media/icons/duotune/general/gen027.svg" />
            </span>
          </a>
        </template>
      </Datatable>
    </div>
    <div v-else>
      <TableSkeleton></TableSkeleton>
    </div>
  </div>
  <NewLicensedDistributorAssociateModal
    :displayModal="displayModal"
    :distAspNetUserId="currentUser.id"
  ></NewLicensedDistributorAssociateModal>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  onUpdated,
  watch,
} from "vue";
import Datatable from "@/components/datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { LicensedDistAssociateListItemDto } from "@/core/types/LicensedDistributorTypes";
import { useStore } from "vuex";
import NewLicensedDistributorAssociateModal from "@/components/modals/forms/NewLicensedDistributorAssociateModal.vue";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import { boolean } from "yup/lib/locale";

export default defineComponent({
  name: "licensed-distributor-associate-listing",
  components: {
    Datatable,
    NewLicensedDistributorAssociateModal,
    TableSkeleton,
  },
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    let displayModal = ref<boolean>(false);
    const isDataReady = ref(false);
    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Email",
        key: "email",
        sortable: true,
      },
      {
        name: "# of Clients",
        key: "numOfClients",
        sortable: true,
      },
      {
        name: "Email Confirmed",
        key: "emailConfirmed",
        sortable: true,
      },
      {
        name: "Status",
        key: "archive",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = computed(() => {
      return store.getters.getLicensedDistributorAssociateList;
    });

    const initCustomers = ref<Array<LicensedDistAssociateListItemDto>>([]);

    store
      .dispatch(Actions.GET_LICENSED_DISTRIBUTOR_ASSOCIATES, currentUser.id)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving your employees.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    onMounted(() => {
      //MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Associate List", ["Associates"]);
    });

    onUpdated(() => {
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    watch(tableData, () => {
      isDataReady.value = true;
    });

    function openModal() {
      displayModal.value = true;
    }

    const deleteLicensedDistributorAssociate = (id) => {
      Swal.fire({
        title: "Are you sure you want to delete this associate?",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          store
            .dispatch(Actions.DELETE_LICENSED_DIST_ASSOCIATE, id)
            .then(() => {
              Swal.fire("Deleted!", "", "success");
              for (let i = 0; i < tableData.value.length; i++) {
                if (tableData.value[i].licensedDistributorId === id) {
                  tableData.value.splice(i, 1);
                }
              }
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText:
                  "There was a problem deleting the associate.",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<LicensedDistAssociateListItemDto> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (
          !Number.isInteger(obj[key]) &&
          !(typeof obj[key] === boolean) &&
          !(typeof obj[key] === "object")
        ) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      currentUser,
      isDataReady,
      initCustomers,
      tableData,
      tableHeader,
      search,
      displayModal,
      deleteLicensedDistributorAssociate,
      searchItems,
      openModal,
    };
  },
});
</script>
